import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import {
  useSymphonyClient,
  useGetQuote,
  useGetCustomConfig,
  useGetPaymentAuthToken
} from '@aventus/symphony-client/hooks';
import {
  useGetProductConfiguration,
  useGetPaymentProvidersConfiguration,
  useGetOrganisationConfiguration,
  useGetProductInterfaceConfiguration
} from '@aventus/configuration-client-context';
import { MvmtCheckout } from '@aventus/mvmt-checkout';
import { getDefaultPaymentPlanTypeQuery } from '@aventus/mvmt-pricing';
import { useGetApplicationConfiguration } from '@aventus/configuration-client-context';
import { PurchasedPolicy, QuoteBundle } from '@aventus/platform';
import { IntelligentQuoteExpiredOrConverted } from '@aventus/mvmt-quote/views/intelligent-quote-expired-or-converted';
import css from './index.css';

export const Checkout: React.FunctionComponent<ICheckout> = props => {
  const { quoteBundle } = useGetQuote(props.match.params.quoteId);
  const { productConfiguration } = useGetProductConfiguration(
    quoteBundle?.requestedQuote.productReferenceID.toLowerCase(),
    quoteBundle?.requestedQuote.coverType.toLowerCase()
  );
  const { productInterfaceConfiguration } = useGetProductInterfaceConfiguration(
    quoteBundle?.requestedQuote.productReferenceID.toLowerCase(),
    quoteBundle?.requestedQuote.coverType.toLowerCase()
  );
  const { paymentProvidersConfiguration } =
    useGetPaymentProvidersConfiguration();
  const { organisationConfiguration } = useGetOrganisationConfiguration();

  const { hasPaymentAuthToken } = useGetPaymentAuthToken(
    organisationConfiguration,
    quoteBundle?.requestedQuote.id
  );

  const symphony = useSymphonyClient();

  const getLoginRoute = () => `/sign-in`;

  // If this quote is a renewal, then we can expect there
  // to be an original policy. This ID would be passed into
  // this view as a query param, so that we can link back into
  // the policy renewal dashboard if necessary.

  const { applicationConfiguration } = useGetApplicationConfiguration();

  const { customConfig } = useGetCustomConfig();

  const defaultPaymentPlanType = getDefaultPaymentPlanTypeQuery(
    props.location.search
  );

  const onFinish = (
    bundle: QuoteBundle,
    policy: PurchasedPolicy,
    success: { message: string; detail?: string }
  ) => {
    const successTitle = !policy.createAccount
      ? customConfig?.userInterface.authentication?.signIn?.postPurchaseTitle
      : success.message;

    const navigateToPolicy =
      customConfig?.userInterface?.authentication?.signIn?.oneTimeSignInLink
        ?.enabled || !policy.createAccount;

    if (navigateToPolicy) {
      props.history.push(`/policy/${policy.id}`, {
        emailAddress: bundle.risk.proposer.emailAddress,
        success: { message: successTitle, detail: success.detail },
        isPostPurchase: true,
        isNewAccount: policy.createAccount
      });
    } else {
      props.history.push(`/create-account`, {
        success: { message: successTitle, detail: success.detail },
        emailAddress: bundle.risk.proposer.emailAddress
      });
    }
  };

  if (
    quoteBundle?.requestedQuote.converted === true &&
    productConfiguration &&
    organisationConfiguration
  ) {
    return (
      <div className={css.scaffolding}>
        <IntelligentQuoteExpiredOrConverted
          title={productConfiguration.quote.converted.title}
          reason={productConfiguration.quote.converted.reason}
          help={productConfiguration.quote.converted.help}
          supportLink={organisationConfiguration.links.support}
          onAcknowledgement={() => props.history.push('/dashboard')}
        />
      </div>
    );
  }

  if (
    quoteBundle?.requestedQuote.expired === true &&
    productConfiguration &&
    organisationConfiguration
  ) {
    let expiryReason = productConfiguration.quote.expired.reason;
    if (quoteBundle?.requestedQuote.quoteType === 'NewBusiness' && productConfiguration.quote.expired.reasonNewBusiness){
      expiryReason = productConfiguration.quote.expired.reasonNewBusiness
    }
    else if (quoteBundle?.requestedQuote.quoteType === 'Renewal' && productConfiguration.quote.expired.reasonRenewal){
      expiryReason = productConfiguration.quote.expired.reasonRenewal
    }

    return (
      <div className={css.scaffolding}>
        <IntelligentQuoteExpiredOrConverted
          title={productConfiguration.quote.expired.title}
          reason={expiryReason}
          help={productConfiguration.quote.expired.help}
          supportLink={organisationConfiguration.links.support}
          onAcknowledgement={() => props.history.push('/dashboard')}
        />
      </div>
    );
  }

  if (
    hasPaymentAuthToken &&
    quoteBundle &&
    productConfiguration &&
    productInterfaceConfiguration &&
    paymentProvidersConfiguration &&
    applicationConfiguration &&
    organisationConfiguration
  ) {
    return (
      <MvmtCheckout
        quoteId={quoteBundle.requestedQuote.id}
        quote={quoteBundle.requestedQuote}
        quoteEmbargoes={quoteBundle.embargoes}
        quotePricing={quoteBundle.requestedQuotePricing}
        defaultPaymentPlanType={defaultPaymentPlanType}
        goToQuoteSummary={props.goToQuoteSummary}
        risk={quoteBundle.risk}
        adjustmentInformation={quoteBundle.mtaInfo}
        productConfiguration={productConfiguration}
        productInterfaceConfiguration={productInterfaceConfiguration}
        paymentProvidersConfiguration={paymentProvidersConfiguration}
        applicationConfiguration={applicationConfiguration}
        organisationConfiguration={organisationConfiguration}
        customConfiguration={customConfig}
        policyReferenceID={quoteBundle.originalPolicyMeta?.policyReferenceID}
        emailAddress={quoteBundle.risk.proposer.emailAddress}
        callingApplication={'symphony'}
        lifecycles={{
          onInitialise: symphony.initialisePayment,
          onInitialiseSubscription: symphony.initialiseSubscription,
          onPoll: symphony.getPayment,
          onGetPurchasedPolicy: symphony.getPolicyForPayment,
          onGetPolicy: symphony.getPolicy,
          onCreateFinanceAgreement: symphony.createFinanceAgreement,
          onUpdateFinanceAgreement: symphony.updateFinanceAgreement,

          onCreateBNPFinanceAgreement: symphony.createBNPFinanceAgreement,
          onCreateBNPFinanceAgreementNoDeposit:
            symphony.createBNPFinanceAgreementNoDeposit,

          onStripeCreateSetupIntent: symphony.stripeCreateSetupIntent,

          onFatZebraGetConfig: symphony.fatZebraGetConfig,
          onFatZebraGeneratePaymentIntentVerification:
            symphony.fatZebraGeneratePaymentIntentVerification,
          onFatZebraAnnualPurchase: symphony.fatZebraAnnualPurchase,
          onFatZebraSubscriptionPurchase: symphony.fatZebraSubscriptionPurchase,
          onFatZebraMidTermAdjustmentRefund:
            symphony.fatZebraMidTermAdjustmentRefund,
          onFatZebraSubscriptionMTA: symphony.fatZebraSubscriptionMTA,

          onAdjustRefund: symphony.adjustRefund,
          onAdjustSubscription: symphony.adjustSubscription,
          onApplyVoucherCode: symphony.applyVoucherCode,
          onRemoveVoucherCode: symphony.removeVoucherCode,
          onGetPolicyPaymentMethod: symphony.getPolicyPaymentMethod,
          onFinish: (policy, success) => onFinish(quoteBundle, policy, success),
          onClearPaymentToken: symphony.clearPaymentToken
        }}
        isAuthenticated={symphony.isUserAuthenticated}
        onIsInvalid={() => props.history.push('/dashboard')}
        onIsUnauthenticated={() => {
          const config = {
            isMidFlowAuth: true,
            emailAddress: quoteBundle.risk.proposer.emailAddress,
            onSuccessGoToRoute: `${props.location.pathname}${props.location.search}`,
            passToNextRoute: props.location.state
          };
          props.history.replace(
            customConfig?.userInterface?.authentication?.signIn
              ?.oneTimeSignInLink?.enabled
              ? '/sign-in'
              : '/sign-up',
            config
          );
          return;
        }}
        onBackToRenewal={
          quoteBundle.originalPolicyMeta?.policyID
            ? () =>
                props.history.push(
                  `/policy/${quoteBundle.originalPolicyMeta?.policyID}/renew`
                )
            : undefined
        }
        goToLogin={() => {
          props.history.push(getLoginRoute(), {
            origin: props.location.pathname,
            isMidFlowAuth: true,
            onSuccessGoToRoute: props.location.pathname
          });
        }}
        goHome={() => props.history.push('/dashboard')}
      />
    );
  }

  return null;
};

interface IPathParams {
  quoteId: string;
}

interface ICheckout extends RouteComponentProps<IPathParams> {
  goToQuoteSummary: (quoteID: string, quotePlan: string) => void;
}
