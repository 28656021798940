import * as React from 'react';
import { BladeInput_Frame } from '../_frame';
import { BladeInput_Label } from '../_label';
import { useInput } from '../use-input';
import { useSelect } from './use-select';
import { useMobileDetect } from '@aventus/pockethooks';
import { BladeIcon } from '../../index';
import { MobileSelect } from './components/mobile-select';
import SuperSelect from 'react-super-select';
import css from './index.css';
import useDispatchEvent from '@aventus/pockethooks/use-dispatch-event';

export const BladeInputSelect: React.FunctionComponent<
  IBladeInputSelect
> = props => {
  const inputProps = useInput(props);

  const { childComponentUpdated, packageIsLoading } = useDispatchEvent();

  const onChange = (value: any, referenceID?: string | undefined) => {
    props.onChange(value, referenceID, props.parentQuestion);

    props.parentQuestion && childComponentUpdated(props.parentQuestion);

    // if there's a parent question, we know this select is part of a travel package question
    props.parentQuestion && packageIsLoading(value != null);
  };

  const {
    options,
    onSelect,
    isSelectOpen,
    setIsSelectOpen,
    stepUp,
    stepDown,
    DEFAULT,
    controlDisabled
  } = useSelect(
    inputProps.value,
    props.options,
    props.matchOn,
    props.labelOn,
    onChange,
    props.multiSelect,
    props.defaultLabel
  );

  const device = useMobileDetect();

  const selectedOption = inputProps.value
    ? options.find((option: any) => {
        return option[props.matchOn] === inputProps.value[props.matchOn];
      })
    : !props.multiSelect
    ? DEFAULT
    : undefined;
    
  return (
    <BladeInput_Frame
      hasBeenInteractedWith={inputProps.hasBeenInteractedWith}
      error={inputProps.error}
    >
      <BladeInput_Label id={inputProps.id} label={inputProps.label} />
      {inputProps.questionImage && (
        <div>
          <img
            src={inputProps.questionImage}
            alt={'question'}
            className={css.imagebox}
          />
        </div>
      )}
      {!device.isMobile() || props.supressMobile ? (
        <>
          <div className={css.select}>
            {props.isStepper && (
              <div
                onClick={() => stepDown()}
                className={css.select_stepperAction}
              >
                <BladeIcon
                  className={css.select_stepperAction_decrement}
                  name={'faMinus'}
                />
              </div>
            )}

            <div className={css.select_wrap}>
              <SuperSelect
                customClass={`blade-input-superselect ${inputProps.className}`}
                initialValue={selectedOption}
                dataSource={options}
                onChange={onSelect}
                onBlur={inputProps.onBlur}
                onOpenDropdown={() => setIsSelectOpen(true)}
                onCloseDropdown={() => setIsSelectOpen(false)}
                keepOpenOnSelection={props.multiSelect ? true : false}
                searchable={!props.hideSearch && options.length > 10}
                clearable={props.multiSelect ? true : false}
                multiple={props.multiSelect ? props.multiSelect : false}
                optionLabelKey={props.labelOn || 'text'}
                tags={props.multiSelect ? true : false}
                optionValueKey={props.matchOn}
                forceDefaultBrowserScrolling={true}
                deselectOnSelectedOptionClick={props.multiSelect ? true : false}
                disabled={controlDisabled}
              />

              <BladeIcon
                className={css.select_input_icon}
                name={isSelectOpen ? 'faCaretUp' : 'faCaretDown'}
              />
            </div>

            {props.isStepper && (
              <div
                onClick={() => stepUp()}
                className={css.select_stepperAction}
              >
                <BladeIcon
                  className={css.select_stepperAction_increment}
                  name={'faPlus'}
                />
              </div>
            )}
          </div>
        </>
      ) : (
        <MobileSelect
          options={options}
          initialValue={selectedOption}
          classNames={inputProps.className}
          multiple={props.multiSelect}
          matchOn={props.matchOn}
          onChange={onSelect}
        />
      )}
    </BladeInput_Frame>
  );
};

export interface IBladeInputSelect extends IBladeInput {
  options: any[];
  matchOn: string;
  isStepper?: boolean;
  labelOn?: string;
  defaultLabel?: string;
  hideSearch?: boolean;
  multiSelect?: boolean;
  supressMobile?: boolean;
  isLoadingPrice?: boolean;
}
