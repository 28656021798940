import * as React from 'react';

import { BladeIcon, BladeLink } from '@aventus/blade';

import { ReactNode } from 'react';
import styles from './css/styles.css';

interface PackageFooterProps {
  packageSelected: boolean;
  packageLimit: string | null;
  limitControls: ReactNode | null;
  hasMoreInfo: boolean;
  canBeRemoved: boolean;
  onMoreInfoClick: () => void;
  unselectPackage: () => void;
  buttonsDisabled: boolean;
  moreInfoText?: string;
}

const PackageFooter = (props: PackageFooterProps) => {
  return (
    <div className={styles.packageFooter}>
      {props.packageLimit && (
        <div className={styles.packageLimit}>
          <strong>Limit:</strong>&nbsp;{props.packageLimit}
        </div>
      )}

      {props.limitControls && (
        <div className={`${styles.packageLimit} ${styles.variable}`}>
          <strong className={styles.limitLabel}>Limit:</strong>{' '}
          {props.limitControls}
        </div>
      )}

      {props.hasMoreInfo && (
        <BladeLink
          className={`${styles.moreInfo}  ${styles.mobileOnly}`}
          onClick={props.onMoreInfoClick}
        >
          {props.moreInfoText ?? "More info"}&nbsp; <BladeIcon name={'faChevronRight'} />
        </BladeLink>
      )}

      {props.packageSelected && props.canBeRemoved && (
        <BladeLink
          className={`${styles.removeFromQuoteLink} ${styles.desktopOnly}`}
          onClick={props.unselectPackage}
          style={{ pointerEvents: props.buttonsDisabled ? 'none' : 'auto' }}
        >
          <div className={styles.removeFromQuoteIcon}>
            <BladeIcon name={'faTrashAlt'} />
          </div>
          &nbsp;Remove
        </BladeLink>
      )}
    </div>
  );
};

export default PackageFooter;
