import * as React from 'react';
import { AdditionalComponentPrices, IntelligentQuoteQuestion } from '@aventus/platform';
import { currencyToHumanReadable } from '@aventus/pocketknife';
import PackagePanel from '../package-selector/package-panel';
import PackageInfoText from '../package-selector/package-info';
import { usePackageSelector } from '../package-selector/use-package-selector';
import PackageFooter from '../package-selector/package-footer';
import PackageButtons from '../package-selector/package-buttons';
import PackageMoreInfoModal from '../package-selector/package-more-info-modal';

export interface IBladeAddonPackage extends IBladeInput {
    questionData: IntelligentQuoteQuestion;
    additionalComponentPrices?: AdditionalComponentPrices;
    selectedInOriginalRisk: boolean | null;
    isLoadingPrice: boolean;
  }
  
  export const BladeAddonPackage = (props: IBladeAddonPackage) => {
    const packageSelectedValue = props.value === true;
  
    const {
      setShowMoreInfo,
      showMoreInfo,
      packageLimit,
      packageIsSelected,
      packageIsBeingInteractedWith,
      packageIsBeingAdded,
      packageIsBeingRemoved,
      handlePackageClick,
      childComponentUpdated,
      buttonsDisabled
    } = usePackageSelector(
      props.questionData,
      packageSelectedValue,
      props.selectedInOriginalRisk,
      props.isLoadingPrice,
      props.onChange,
      props.additionalComponentPrices,
      undefined,
      undefined
    );

    const resolvePackagePrice = () => {
        if (!props.additionalComponentPrices || !props.additionalComponentPrices[props.questionData.riskPath]){
            return null;
        }

        const packagePricing = props.additionalComponentPrices[props.questionData.riskPath];

        const annualPrice = packagePricing.packagePrice;

        const price = packagePricing.monthlyPrice ?? packagePricing.packagePrice;
        const isMonthly = packagePricing.monthlyPrice !== null; 

        if (annualPrice === null || annualPrice.value  === 0){
            return null;
        }

        let amount = price.value;

        const prefix = props.value
            ? price.value > 0
            ? '+'
            : '-'
            : '';
    
        const formattedPrice = currencyToHumanReadable({
            currencyCode: price.currencyCode,
            value: Math.abs(amount)
        });
    
        return prefix + formattedPrice + " / " + (isMonthly ? "month" : "year");
    };
  
    return (
      <PackagePanel
        questionReferenceID={props.questionData.referenceID}
        packageIsSelected={packageIsSelected}
        questionText={props.questionData.text.questionText}
      >
        <PackageInfoText
          packageDescription={props.questionData.text.summaryText}
          onMoreInfoClick={() => setShowMoreInfo(true)}
          moreInfoText={"More details"}
        />
  
        <PackageFooter
          canBeRemoved={true}
          hasMoreInfo={props.questionData.text.summaryText !== null}
          onMoreInfoClick={() => setShowMoreInfo(true)}
          packageLimit={packageLimit}
          unselectPackage={() => handlePackageClick(false)}
          packageSelected={packageIsSelected}
          buttonsDisabled={buttonsDisabled}
          limitControls={null}
          moreInfoText={"More details"}
        />
        <PackageButtons
          isToggleMode={false}
          canBeRemoved={true}
          packageSelected={packageIsSelected}
          setPackageSelected={handlePackageClick}
          packagePrice={resolvePackagePrice()}
          showAddButton={!packageIsSelected}
          packageWasInteractedWith={packageIsBeingInteractedWith}
          packageIsBeingAdded={packageIsBeingAdded}
          packageIsBeingRemoved={packageIsBeingRemoved}
          childComponentUpdated={childComponentUpdated}
          addText={"Add for"}
          addedText={"Selected"}
          buttonsDisabled={buttonsDisabled}
        />
        {showMoreInfo && (
          <PackageMoreInfoModal
            title={props.questionData.text.questionText}
            summaryText={props.questionData.text.summaryText}
            detailText={props.questionData.text.detailText}
            onClose={() => setShowMoreInfo(false)}
          />
        )}
      </PackagePanel>
    );
  };
