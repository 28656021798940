import { IDateRange, IDateRangeValue } from './types';
import { useEffect, useState } from 'react';

import { ChangePermissions } from '@aventus/platform';
import dateWithinRange from '@aventus/pocketknife/date-within-range';
import { isArray } from 'lodash';
import moment from 'moment';
import { useDateRangeConstraints } from './use-date-range-constraints';
import useDispatchEvent from '@aventus/pockethooks/use-dispatch-event';
import { EventType } from '@aventus/pocketknife/dispatch-event';

export const useDateRange = (
  value: IDateRangeValue,
  rawValidation: Array<string>,
  onChange: (value: IDateRangeValue) => any,
  permissions?: ChangePermissions,
  isLoadingPrice?: boolean,
  parentQuestion?: string
) => {
  const [dates, setDates] = useState<IDateRange>({
    startDate: new Date(value.from),
    endDate: new Date(value.to)
  });

  const permittedDateRange = useDateRangeConstraints(
    rawValidation,
    dates,
    permissions?.canModifyFrom === false
  );
  
  const { packageIsLoading } = useDispatchEvent();

  const [controlDisabled, setControlDisabled] = useState(false);

  useEffect(() => {
    if (dates.startDate && dates.endDate) {
      // update state change to risk
      onChange({
        from: moment(dates.startDate).format('YYYY-MM-DDTHH:mm:ss'),
        to: moment(dates.endDate).format('YYYY-MM-DDTHH:mm:ss')
      });
    }
  }, [dates]);

  const onInputChange = (
    changeProps: [Date | null, Date | null] | Date | null
  ) => {
    // changeProps can be a range or a single (end) date
    if (isArray(changeProps)) {
      const [newStartDate, newEndDate] = changeProps;

      if (newEndDate) {
        endDateSelected(newEndDate);
      } else if (newStartDate) {
        startDateSelected(newStartDate);
      } else {
        setDates({ startDate: null, endDate: null });
      }

      // If there's a parent question, we know this input is part of a travel package question
      if (parentQuestion && newStartDate && newEndDate) {
        packageIsLoading(true);
      }
    } else if (changeProps !== null) {
      endDateSelected(changeProps);
    }
  };

  const startDateSelected = (newStartDate: Date) => {
    if (dateIsPermitted(newStartDate)) {
      setDates({
        startDate: newStartDate,
        endDate: null
      });
    }
  };

  const endDateSelected = (newEndDate: Date) => {
    if (dateIsPermitted(newEndDate)) {
      setDates(currentState => ({
        ...currentState,
        endDate: newEndDate
      }));
    }
  };

  const dateIsPermitted = (date: Date) =>
    dateWithinRange(date, permittedDateRange.min, permittedDateRange.max);

  const onClose = () => {
    if (dates.endDate === null) {
      setDates(currentState => ({
        ...currentState,
        endDate: permittedDateRange.min
      }));
    }
  };

  const handlePackageIsLoading = (event: any) => {
    setControlDisabled(event.detail.isLoading);
  };

  useEffect(() => {
    packageIsLoading(isLoadingPrice === true);
  }, [isLoadingPrice]);
  
  useEffect(() => {
    window.addEventListener(EventType.PackageIsLoading, handlePackageIsLoading);

    return () => {
      window.removeEventListener(
        EventType.PackageIsLoading,
        handlePackageIsLoading
      );
    };
  }, []);

  return {
    onInputChange,
    onClose,
    minDate: permittedDateRange.min,
    maxDate: permittedDateRange.max,
    ...dates,
    controlDisabled
  };
};
