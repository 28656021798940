import React from 'react';
import {
  BladeButton,
  BladeLoadOver,
  BladeInputVoucher,
  BladeSurface
} from '@aventus/blade';
import {
  Quote,
  PricingSet,
  Risk,
  PaymentPlanType,
  CallingApplication
} from '@aventus/platform';
import {
  InsuranceProductConfiguration,
  PaymentProvidersConfiguration,
  OrganisationConfiguration,
  ProductInterfaceConfiguration,
  ApplicationConfiguration
} from '@aventus/configuration';
import { PaymentPlanSelector } from '@aventus/mvmt-pricing';
import { Lifecycles } from '../../../interface/lifecycles';
import { ICheckoutBoundaryError } from '../../../boundary/types';
import { stringReplacer } from '@aventus/pocketknife/string-replacer';
import { currencyToHumanReadable } from '@aventus/pocketknife/currency-to-human-readable';
import { getPaymentGuide } from '../../../services/get-payment-guide';
import { getPaymentGuideFooter } from '../../../services/get-payment-guide-footer';
import { useApplicationTimezone } from '@aventus/application-timezone';
import checkoutCss from '../index.css';
import { useCheckout } from '../use-checkout';
import { StripePayWithCreditCard } from '@aventus/payment-provider-stripe';
import { FatZebraWrapper } from '@aventus/payment-provider-fatzebra';
import { PclSetupDirectDebit } from '@aventus/payment-provider-pcl';
import { ReceiptPay } from '../../../components/receipt-pay';
import { useBladeInlineError } from '@aventus/blade/inline-error';
import { BNPComponent } from '@aventus/payment-provider-bnp';
import { IntelligentQuoteDecline } from '@aventus/mvmt-quote/views/intelligent-quote-decline';

export const CheckoutNew: React.FunctionComponent<ICheckoutNew> = props => {
  const { getError, clearErrors } = useBladeInlineError();
  const {
    selectedPricingPlan,
    setSelectedPricingPlan,
    payment,
    depositPayment,
    onProviderPaymentReady,
    canMakePayment,
    checkoutNow,
    payNow,
    isCheckingOut,
    setIsCheckingOut,
    isCheckedOut,
    quotePricing,
    updateQuotePricing
  } = useCheckout(
    props.quote,
    props.quotePricing,
    props.defaultPaymentPlanType,
    undefined,
    props.lifecycles,
    props.productConfiguration,
    props.organisationConfiguration
  );

  const [isCardEntryComplete, setIsCardEntryComplete] =
    React.useState<boolean>(false);

  const [isPremiumCreditComplete, setIsPremiumCreditComplete] =
    React.useState<boolean>(false);

  const [isBNPComplete, setIsBNPComplete] = React.useState<boolean>(false);

  const isStripeComponentRequired =
    payment === 'Stripe' || depositPayment === 'Stripe';

  const isFatZebraComponentRequired =
    payment === 'FatZebra' || depositPayment === 'FatZebra';

  const isBnpComponentRequired = payment === 'BNP';

  const isPremiumCreditRequired = payment === 'PremiumCredit';

  const discountInfo = selectedPricingPlan?.discountInfo || undefined;

  const { toHumanReadable } = useApplicationTimezone();

  
  const isQuoteDeclined = props.quote?.declined

  if (isQuoteDeclined) {
    return (
      <IntelligentQuoteDecline
          documentTitle={props.productConfiguration.quote.renewalDecline.title}
          title={props.productConfiguration.quote.decline.unrecoverableTitle}
          declineWithNoReason={
            props.productConfiguration.quote.decline.declineWithNoReason
          }
          help={props.productConfiguration.quote.decline.help}
          onAcknowledgement={() => props.goHome()}
          width='m'
        />
    )
  }

  return (
    <>
      <BladeLoadOver isLoading={isCheckingOut} isSuccessful={isCheckedOut} />

      <div className={checkoutCss.checkout_frame_content}>
        {quotePricing && (
          <PaymentPlanSelector
            label={props.productConfiguration.pricing.paymentPlanToggleLabel}
            description={
              props.productConfiguration.pricing.paymentPlanDescription
            }
            plans={quotePricing}
            plansConfiguration={props.productConfiguration.pricing.paymentPlans}
            selectedPaymentPlan={selectedPricingPlan}
            setSelectedPricingPlan={setSelectedPricingPlan}
          />
        )}

        <BladeSurface
          shouldRespectViewWidth={true}
          className={checkoutCss.checkout_frame_voucher}
        >
          <BladeInputVoucher
            value={null}
            onChange={() => null}
            name="checkoutVoucher"
            risk={props.quote.risk}
            quoteID={props.quote.id}
            voucherValues={{
              ...(discountInfo
                ? {
                    voucherInfo: {
                      id: discountInfo.id,
                      code: discountInfo.code,
                      terms: discountInfo.terms,
                      description: discountInfo.description
                    }
                  }
                : {})
            }}
            checkoutCallBack={updateQuotePricing}
            voucherActions={{
              applyVoucher: props.lifecycles.onApplyVoucherCode,
              removeVoucher: props.lifecycles.onRemoveVoucherCode
            }}
            fieldsetProps={{
              question: props.productConfiguration.checkout.vouchers.label
            }}
            inputVariant={props.productInterfaceConfiguration.inputs}
          />
        </BladeSurface>

        {selectedPricingPlan &&
          isFatZebraComponentRequired &&
          props.paymentProvidersConfiguration.FatZebra && (
            <FatZebraWrapper
              payment={{
                amount: selectedPricingPlan.upfrontPrice.value,
                currency: selectedPricingPlan.upfrontPrice.currencyCode,
                reference: props.quote.quoteReferenceID
              }}
              quoteID={props.quote.id}

              verificationProps={{
                quoteID: props.quote.id,
                paymentPlanReferenceID:
                  selectedPricingPlan.paymentPlan.paymentPlanReferenceID,
                encryptedDiscount: quotePricing?.discountState || undefined
              }}
              title={
                props.productConfiguration.checkout.payment.title ||
                'Pay by card'
              }
              titleWithOptions={
                props.productConfiguration.checkout.payment.titleWithOptions
              }
              inputVariant={props.productInterfaceConfiguration.inputs}
              guide={getPaymentGuide(
                props.productConfiguration.checkout.payment,
                depositPayment === 'FatZebra' ? true : false,
                {
                  initPaymentDate:
                    selectedPricingPlan.firstRecurringPaymentDate,
                  upfrontPrice: selectedPricingPlan.upfrontPrice,
                  monthlyPrice: selectedPricingPlan.monthlyPrice
                },
                toHumanReadable
              )}
              guideFooter={getPaymentGuideFooter(
                props.productConfiguration.checkout.payment,
                depositPayment === 'FatZebra' ? true : false,
                selectedPricingPlan,
                toHumanReadable
              )}
              onPayNow={payNow}
              onComplete={request =>
                onProviderPaymentReady('FatZebra', request)
              }
              setIsComplete={setIsCardEntryComplete}
              setIsCheckingOut={setIsCheckingOut}
              isCheckingOut={isCheckingOut}
              error={getError('fatzebraerror')}
              fatZebraConfig={props.lifecycles.onFatZebraGetConfig}
              fatZebraPaymentIntentVerification={
                props.lifecycles.onFatZebraGeneratePaymentIntentVerification
              }
            />
          )}

        {selectedPricingPlan &&
          props.paymentProvidersConfiguration.Stripe?.key &&
          isStripeComponentRequired && (
            <StripePayWithCreditCard
              stripeKey={props.paymentProvidersConfiguration.Stripe.key}
              title={props.productConfiguration.checkout.payment.title}
              titleWithOptions={
                props.productConfiguration.checkout.payment.titleWithOptions
              }
              inputVariant={props.productInterfaceConfiguration.inputs}
              guide={getPaymentGuide(
                props.productConfiguration.checkout.payment,
                depositPayment === 'Stripe' ? true : false,
                {
                  initPaymentDate:
                    selectedPricingPlan.firstRecurringPaymentDate,
                  upfrontPrice: selectedPricingPlan.upfrontPrice,
                  monthlyPrice: selectedPricingPlan.monthlyPrice
                },
                toHumanReadable
              )}
              guideFooter={getPaymentGuideFooter(
                props.productConfiguration.checkout.payment,
                depositPayment === 'Stripe' ? true : false,
                selectedPricingPlan,
                toHumanReadable
              )}
              onComplete={request => onProviderPaymentReady('Stripe', request)}
              setIsComplete={setIsCardEntryComplete}
              error={getError('stripecard')}
            />
          )}

        {selectedPricingPlan &&
          selectedPricingPlan.paymentPlan.paymentProvider === 'BNP' && (
            <BNPComponent
              title={props.productConfiguration.checkout.finance.title}
              risk={props.risk}
              submitCallback={setIsBNPComplete}
              onReady={request => onProviderPaymentReady('BNP', request)}
              financeGuarentee={
                props.productConfiguration.checkout.finance.financeGuarantee
              }
              financeTerms={stringReplacer(
                props.productConfiguration.checkout.finance.financeTerms,
                {
                  $$_first_monthly_direct_debit: currencyToHumanReadable(
                    selectedPricingPlan.firstInstalmentAmount
                  ),
                  $$_number_of_months: String(
                    selectedPricingPlan.numberOfInstallments
                  ),
                  ...(selectedPricingPlan.premiumFinanceInfo
                    ? {
                        $$_total_to_borrow: currencyToHumanReadable(
                          selectedPricingPlan.premiumFinanceInfo.loanAmount
                        ),
                        $$_interest_rate: String(
                          selectedPricingPlan.premiumFinanceInfo.interestRate
                        ),
                        $$_apr_rate: String(
                          selectedPricingPlan.premiumFinanceInfo.aprRate
                        ),
                        $$_total_amount_payable: currencyToHumanReadable(
                          selectedPricingPlan.premiumFinanceInfo?.totalPayable
                        )
                      }
                    : {})
                }
              )}
              error={getError('bnperror')}
              encryptedCreditPlan={selectedPricingPlan.encryptedState}
            />
          )}

        {isPremiumCreditRequired && selectedPricingPlan && (
          <PclSetupDirectDebit
            title={props.productConfiguration.checkout.finance.title}
            customerName={`${props.risk.proposer.personName.firstName} ${props.risk.proposer.personName.lastName}`}
            guide={stringReplacer(
              props.productConfiguration.checkout.finance.guide,
              {
                $$_monthly_price: currencyToHumanReadable(
                  selectedPricingPlan.monthlyPrice
                )
              }
            )}
            guideFooter={
              props.productConfiguration.checkout.finance.guideFooter
            }
            guideFooterMoreDetails={{
              financeGuarantee:
                props.productConfiguration.checkout.finance.financeGuarantee,
              financeTerms:
                props.productConfiguration.checkout.finance.financeTerms
            }}
            onReady={request =>
              onProviderPaymentReady('PremiumCredit', request)
            }
            setIsComplete={setIsPremiumCreditComplete}
            error={getError('pclfinance')}
          />
        )}

        <div className={checkoutCss.checkout_actions}>
          {props.quote.quoteType === 'Renewal' &&
            props.onBackToRenewal &&
            props.callingApplication === 'symphony' && (
              <BladeButton
                className={checkoutCss.checkout_actions_back}
                variant={'secondary'}
                onClick={() => props.onBackToRenewal?.()}
              >
                {props.productConfiguration.checkout.callToActions
                  .backToRenewal || 'Back to Renewal'}
              </BladeButton>
            )}

          <BladeButton
            onClick={async () => {
              clearErrors();

              if (isFatZebraComponentRequired) {
                checkoutNow();
              } else {
                payNow();
              }
            }}
            isWorking={isCheckingOut}
            isDisabled={
              !canMakePayment ||
              (isStripeComponentRequired && !isCardEntryComplete) ||
              (isPremiumCreditRequired && !isPremiumCreditComplete) ||
              (isBnpComponentRequired && !isBNPComplete)
            }
          >
            {props.productConfiguration.checkout.callToActions.payNow ||
              'Buy Now'}
          </BladeButton>
        </div>
      </div>

      <div className={checkoutCss.checkout_frame_receipt}>
        {selectedPricingPlan && (
          <ReceiptPay
            productLabel={props.productConfiguration.product.label}
            policyReferenceID={props.policyReferenceID}
            taxLabel={
              props.productConfiguration.pricing.receiptTaxDescription ||
              props.productConfiguration.pricing.taxDescription
            }
            displayAprLabel={props.productConfiguration.pricing.displayAPRLabel}
            fixedFeeTotalLabel={
              props.productConfiguration.pricing.fixedFeeTotalLabel
            }
            quote={props.quote}
            risk={props.risk}
            plan={selectedPricingPlan}
            receiptStyledFoot={
              props.applicationConfiguration?.receiptStyledFoot
            }
            displayNumberOfInstalments={
              props.productConfiguration.pricing.displayNumberOfInstalments
            }
            totalAnnualCostLabel={
              props.productConfiguration.pricing.totalAnnualCostLabel
            }
            discountSubTotalLabel={
              props.productConfiguration.pricing.discountSubTotalLabel
            }
            receiptPaymentPlanLabel={
              props.productConfiguration.pricing.paymentPlans.find(
                p =>
                  p.paymentPlanReferenceType ===
                  selectedPricingPlan.paymentPlan.type
              )?.label
            }
          />
        )}
      </div>
    </>
  );
};

export interface ICheckoutNew {
  quote: Quote;
  quotePricing: PricingSet;
  defaultPaymentPlanType?: PaymentPlanType;
  policyReferenceID?: string;
  risk: Risk;
  productConfiguration: InsuranceProductConfiguration;
  productInterfaceConfiguration: ProductInterfaceConfiguration;
  paymentProvidersConfiguration: PaymentProvidersConfiguration;
  organisationConfiguration: OrganisationConfiguration;
  applicationConfiguration: ApplicationConfiguration;
  lifecycles: Lifecycles;
  errors?: ICheckoutBoundaryError;
  callingApplication: CallingApplication;
  onBackToRenewal?: () => void;
  goHome: () => void;
}
