import './css/daterange.css';

import { BladeIcon } from '../../icon';
import { BladeInput_Frame } from '../_frame';
import { BladeInput_Label } from '../_label';
import { ChangePermissions } from '@aventus/platform';
import DatePicker from 'react-datepicker';
import DateRangeValue from './date-range-value';
import { IDateRangeValue } from './types';
import React from 'react';
import css from './css/index.css';
import cx from 'classnames';
import { useDateRange } from './use-date-range';
import { useInput } from '../use-input';
import moment from 'moment';

export const BladeInputDateRange: React.FunctionComponent<
  IBladeInputDateRange
> = ({
  timezone,
  validationRules = [],
  toApplicationReadable,
  toPlatformReadable,
  isLoadingPrice,
  ...props
}) => {
  const {
    hasBeenInteractedWith,
    error,
    className = '',
    label,
    id
  } = useInput(props);

  const { minDate, maxDate, startDate, endDate, onInputChange, onClose, controlDisabled } =
    useDateRange(
      props.value,
      validationRules,
      props.onChange,
      props.permissions,
      isLoadingPrice,
      props.parentQuestion
    );

  const dateFormat = props.dateFormat ? props.dateFormat : 'EEE d MMMM yyyy';
  const selectsRange = props.permissions?.canModifyFrom !== false;
  const InputDisplay = React.forwardRef(DateRangeValue);

  return (
    <BladeInput_Frame
      hasBeenInteractedWith={hasBeenInteractedWith}
      error={error}
    >
      {label && <BladeInput_Label id={id} label={label} />}

      <div className={css.daterange_wrapper}>
        <BladeIcon className={css.daterange_icon} name="faCalendarAlt" />

        <DatePicker
          selected={selectsRange ? undefined : endDate}
          selectsRange={selectsRange}
          selectsEnd={!selectsRange}
          startDate={startDate}
          endDate={endDate}
          minDate={!selectsRange ? moment().toDate() : minDate}
          maxDate={maxDate}
          onChange={onInputChange}
          className={cx(className, css.daterange_input)}
          showPopperArrow={false}
          dateFormat={dateFormat}
          monthsShown={2}
          selectsDisabledDaysInRange
          onCalendarClose={onClose}
          customInput={
            selectsRange ? <InputDisplay className={className} /> : undefined
          }
          disabled={controlDisabled}
        />
      </div>
    </BladeInput_Frame>
  );
};

export interface IBladeInputDateRange extends IBladeInput {
  timezone: string | undefined;
  toApplicationReadable: any;
  toPlatformReadable: any;
  validationRules: Array<string>;
  dateFormat?: string;
  formatOutput?: boolean;
  permissions?: ChangePermissions;
  value: IDateRangeValue;
  isLoadingPrice?: boolean;
}
